import React from 'react';
import { Typography, Grid as MuiGrid } from '@material-ui/core';
import styled from 'styled-components';
import Section from './section';
import FaqCard from '../cards/faqCard';

const FaqSection = styled(Section)`
  padding: 0 2rem 4rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 0 4rem 0;
  }
`;

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
`;

const FAQs = ({ faqs }) => (
  <FaqSection maxWidth="lg">
    {faqs && (
      <MuiGrid container>
        {/* FAQ Section */}
        <MuiGrid item xs={12} component={Typography} variant="h2">
          {faqs.heading == null ? '' : faqs.heading}
        </MuiGrid>
        <Grid item xs={12} component={Typography} variant="h5">
          {faqs.subheading && faqs.subheading}
        </Grid>
        <Grid item xs={12} container>
          {faqs.faqList.length &&
            faqs.faqList.map((faq, index) => <FaqCard key={index} faq={faq} />)}
        </Grid>
      </MuiGrid>
    )}
  </FaqSection>
);
export default FAQs;
