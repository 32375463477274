import React from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';

const CardContainer = styled(Grid)`
  /* Style */
  border-radius: 5px;
  background: ${props => props.theme.palette.primary.light2};
  margin: 0.5rem 0;
`;

const CardHeader = styled(Grid)`
  /* Style */
  border-radius: 5px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  background: ${props => props.theme.palette.primary.main};
  color: white;
`;

const CardBody = styled(Grid)`
  background: transparent;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  color: black;

  padding: 0 1rem;
  * {
    margin: 0;
    padding: 0.5rem 0;
    /* text-align: left; */
  }
`;

const FaqCard = ({ faq }) => (
  <CardContainer xs={12} item container>
    <CardHeader
      xs={2}
      sm={1}
      item
      container
      direction="column"
      justify="center"
      component={Typography}
      variant="h2"
      align="center"
    >
      ?
    </CardHeader>
    <CardBody xs item container direction="column">
      <Grid item component={Typography} variant="body1">
        <b>Question:</b> {faq.question == null ? 'Question Text' : faq.question}
      </Grid>
      <Grid item component={Typography} variant="body1">
        <b>Answer:</b> {faq.answer == null ? 'Answer Text' : faq.answer}
      </Grid>
    </CardBody>
  </CardContainer>
);

export default FaqCard;
