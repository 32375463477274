import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Body from '../components/sections/body';
import Awards from '../components/sections/awards';
import Request from '../components/sections/request';
import Reviews from '../components/sections/reviews';
import FAQs from '../components/sections/faqs';
import Quote from '../components/sections/quote';
import SEO from '../components/seo';

const Page = ({ data }) => {
  const page = data.sanityCustomPage;
  return (
    <Layout heroHeading={page.heading && page.heading} heroImage={page.image}>
      <SEO title={page.heading == null ? 'Page' : page.heading} />
      {/* Body */}
      {page._rawBodySection.text && (
        <Body
          heading={page.bodySection.heading}
          content={page._rawBodySection.text}
        />
      )}
      {/* Faqs */}
      {page.faqSection && <FAQs faqs={page.faqSection} />}
      {/* Reviews */}
      {page.reviewSection && <Reviews reviews={page.reviewSection} />}
      {/* Request Form */}
      {page.requestFormToggle && <Request />}
      {/* Awards */}
      {page.awardSection && <Awards awards={page.awardSection} />}
      {/* Quote */}
      {page.quoteToggle && <Quote />}
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($id: String) {
    sanityCustomPage(id: { eq: $id }) {
      name
      heading
      image {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBodySection
      bodySection {
        heading
      }
      serviceSection {
        heading
        subheading
        serviceList {
          name
          heading
          _rawDescription
          slug {
            current
          }
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      faqSection {
        heading
        subheading
        faqList {
          question
          answer
        }
      }
      reviewSection {
        heading
        subheading
        googleReviewsToggle
        reviewList {
          name
          location
          reviewText
          image {
            asset {
              fluid(maxWidth: 150) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      awardSection {
        awardList {
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      requestFormToggle
      quoteToggle
    }
  }
`;
